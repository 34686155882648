export const environment = {
    production: false,
    appVersion: '48',
    envName: 'stag',
    webUrl: 'https://staging.truckincentral.com',
    apiUrl: 'https://api.staging.truckincentral.com',
    featureUrl: 'https://feature.truckincentral.com/api',
    signalRHubUrl: 'https://api.staging.truckincentral.com',
    navBarBackgroundColor: 'fail',
    permissionBypass: false,
    featureToggleBypass: false,
    dateFormat: "MM/dd/yyyy",
    dateTimeFormat: "MM/dd/yyyy hh:mm a",
    googleAnalytics: "G-G9GYCW1PXY",
    stripeSubscriptionLink: "https://dashboard.stripe.com/test/subscriptions?create=subscription",
    firebase: {
        apiKey: "AIzaSyBmo4-G2PPobxXTCRBdvG65UM8Oti30JIc",
        authDomain: "truck-f62c7.firebaseapp.com",
        databaseURL: "https://truck-f62c7-default-rtdb.firebaseio.com",
        projectId: "truck-f62c7",
        storageBucket: "truck-f62c7.appspot.com",
        messagingSenderId: "1096505717022",
        appId: "1:1096505717022:web:7c98d55d36e9ee0b38d90f",
        measurementId: "G-J16Y810F5Q",
        vapidKey: "BOnGfzGwhlDPhIiD9QhBGoEQOls8REyLLfth86GNWxNkWcIxZodYsLl8tv_MzclBdZwbXrhcrISRlrcqCEvWpNA"
    }
};